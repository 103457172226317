/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { z } from 'zod'

export const schema = {
  '/alerts': z.never().optional(),
  '/bo-users': z.object({
    // NOTE(vladl): With z.coerce.boolean().optional() any string is true
    active: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .optional(),
    page: z.coerce.number().min(0).catch(0).optional(),
    pageSize: z.coerce.number().min(1).catch(25).default(25).optional(),
  }),
  '/challenges/all': z.object({
    onlyDiscoverable: z
      .enum(['true', 'false'])
      .catch('true')
      .transform((value) => value === 'true')
      .default('true'),
    page: z.coerce.number().min(0).catch(0).optional(),
    pageSize: z.coerce.number().min(1).catch(25).default(25).optional(),
    searchGameName: z.string().optional(),
    searchName: z.string().optional(),
    sort: z
      .enum([
        'creation-date-asc',
        'creation-date-desc',
        'game-name-asc',
        'game-name-desc',
        'name-asc',
        'name-desc',
      ])
      .optional(),
  }),
  '/challenges/challenge': z.object({ challengeId: z.string() }),
  '/challenges/challenge-score': z.object({
    challengeId: z.string(),
    userId: z.string(),
  }),
  '/challenges/challenge-scores': z.object({
    challengeId: z.string().default(''),
    includeBadScores: z
      .enum(['true', 'false'])
      .catch('true')
      .transform((value) => value === 'true')
      .default(false),
    onlyDiscoverable: z
      .enum(['true', 'false'])
      .catch('true')
      .transform((value) => value === 'true')
      .optional(),
    page: z.coerce.number().min(0).catch(0).optional(),
    pageSize: z.coerce.number().min(1).catch(25).default(25).optional(),
    promotedChallengeId: z.string().optional(),
    searchGameName: z.string().optional(),
    searchName: z.string().optional(),
    sort: z
      .enum([
        'creation-date-asc',
        'creation-date-desc',
        'game-name-asc',
        'game-name-desc',
        'name-asc',
        'name-desc',
      ])
      .optional(),
  }),
  '/challenges/promoted': z.object({
    page: z.coerce.number().min(0).catch(0).optional(),
    pageSize: z.coerce.number().min(1).catch(25).default(25).optional(),
    seasonId: z.string().optional(),
  }),
  '/challenges/promoted-challenge': z.object({ promotedChallengeId: z.string() }),
  '/challenges/promoted-challenge-score': z.object({
    promotedChallengeId: z.string(),
    userId: z.string(),
  }),
  '/challenges/promoted-challenge-scores': z.object({ promotedChallengeId: z.string() }),
  '/challenges/season': z.object({ seasonId: z.string() }),
  '/cms/dynamic-config': z.object({ configId: z.string() }),
  '/cms/dynamic-config-clients': z.never().optional(),
  '/cms/dynamic-configs': z.object({
    clientAlias: z.string().optional(),
    page: z.coerce.number().min(0).catch(0).optional(),
    pageSize: z.coerce.number().min(1).catch(25).default(25).optional(),
    titleSearch: z.string().optional(),
  }),
  '/cms/feed': z.object({ feedId: z.string() }),
  '/cms/feeds': z.object({
    nameSearch: z.string().optional(),
    page: z.coerce.number().min(0).catch(0).optional(),
    pageSize: z.coerce.number().min(1).catch(25).default(25).optional(),
  }),
  '/cms/page-config': z.object({ configId: z.string() }),
  '/cms/tag': z.object({ tagId: z.string() }),
  '/cms/tags/games': z.object({
    onlyWithInternal: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .optional(),
    page: z.coerce.number().min(0).catch(0).optional(),
    pageSize: z.coerce.number().min(1).catch(25).default(25).optional(),
    parentTagId: z.string().optional(),
    parentTagTitleSearch: z.string().optional(),
    rootOnly: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .optional(),
    sort: z
      .enum([
        'title-asc',
        'title-desc',
        'display-order-asc',
        'display-order-desc',
        'alias-asc',
        'alias-desc',
      ])
      .optional(),
    titleSearch: z.string().optional(),
  }),
  '/cms/tags/mods': z.object({
    onlyWithInternal: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .optional(),
    page: z.coerce.number().min(0).catch(0).optional(),
    pageSize: z.coerce.number().min(1).catch(25).default(25).optional(),
    parentTagId: z.string().optional(),
    parentTagTitleSearch: z.string().optional(),
    rootOnly: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .optional(),
    sort: z
      .enum([
        'title-asc',
        'title-desc',
        'display-order-asc',
        'display-order-desc',
        'alias-asc',
        'alias-desc',
      ])
      .optional(),
    titleSearch: z.string().optional(),
  }),
  '/disputes/all': z.object({
    disputeType: z.enum(['AUTOMATIC', 'MANUAL']).optional(),
    page: z.coerce.number().min(0).catch(0).optional(),
    pageSize: z.coerce.number().min(1).catch(25).default(25).optional(),
    paymentId: z.union([z.string().transform((value) => [value]), z.array(z.string())]).optional(),
    resolution: z.enum(['UNRESOLVED', 'RESOLVED']).optional(),
    resolutionType: z.enum(['NOT_DEFINED', 'AUTOMATIC', 'MANUAL']).optional(),
    sort: z
      .enum([
        'creation-date-asc',
        'creation-date-desc',
        'resolution-date-asc',
        'resolution-date-desc',
      ])
      .default('creation-date-desc')
      .optional(),
    transactionStatus: z.enum(['KEEP', 'REVERT']).optional(),
  }),
  '/disputes/dispute': z.object({ disputeId: z.string() }),
  '/games/all': z.object({
    claimPeriodTime: z.enum(['PAST', 'NOW', 'FUTURE']).optional(),
    // Transforming the string to array because params are always strings
    gameId: z
      .string()
      .transform((value) => [value])
      .optional(),
    internalTrack: z.enum(['published', 'not-published']).optional(),
    minPlays: z.coerce.number().optional(),
    minRatings: z.coerce.number().optional(),
    multiplayer: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .optional(),
    onlyWithoutTags: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .default(false),
    page: z.coerce.number().min(0).catch(0).optional(),
    pageSize: z.coerce.number().min(1).catch(25).default(25).optional(),
    platform: z.enum(['DESKTOP', 'MOBILE']).optional(),
    publicTrack: z.enum(['published', 'not-published']).default('published'),
    search: z.string().optional(),
    // FIX ME?
    sort: z.string().optional(),
    studioId: z
      .string()
      .transform((value) => [value])
      .optional(),
    studioName: z
      .string()
      .transform((value) => [value])
      .optional(),
    studioNameSearch: z.string().optional(),
    tagId: z.union([z.string().transform((value) => [value]), z.array(z.string())]).optional(),
    userEmail: z
      .string()
      .transform((value) => [value])
      .optional(),
    userId: z
      .string()
      .transform((value) => [value])
      .optional(),
    username: z
      .string()
      .transform((value) => [value])
      .optional(),
  }),
  '/games/engine': z.object({ gameEngineId: z.string() }),
  '/games/engines': z.object({
    page: z.coerce.number().min(0).catch(0).optional(),
    pageSize: z.coerce.number().min(1).catch(25).default(25).optional(),
    sort: z.string().optional(),
  }),
  '/games/game': z.object({ gameId: z.string() }),
  '/games/game-challenges': z.object({ gameId: z.string().default('') }),
  '/games/game-item-periods': z.object({
    gameId: z.string(),
    itemId: z.string(),
  }),
  '/games/game-transfer': z.object({ transferId: z.string() }),
  '/games/game-transfers': z.object({
    gameId: z.string().optional(),
    page: z.coerce.number().min(0).catch(0).optional(),
    pageSize: z.coerce.number().min(1).catch(25).default(25).optional(),
    status: z.enum(['ABORTED', 'APPROVED', 'PENDING', 'REJECTED']).default('PENDING'),
    targetStudioId: z.string().optional(),
  }),
  '/games/publisher': z.object({ publisherId: z.string() }),
  '/games/publishers': z.object({
    page: z.coerce.number().min(0).catch(0).optional(),
    pageSize: z.coerce.number().min(1).catch(25).default(25).optional(),
  }),
  '/games/reported-game': z.object({ gameId: z.string() }),
  '/games/reported-games': z.object({
    blocked: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .optional(),
    gameId: z
      .string()
      .transform((value) => [value])
      .optional(),
    hasUnresolvedReports: z
      .enum([
        'ANY',
        'MISCATEGORIZED',
        'OFFENSIVE_MATERIAL',
        'SPAM',
        'UNAUTHORIZED_DISTRIBUTION',
        'NONE',
        'OTHER',
        'RECONSIDERATION',
      ])
      .catch('ANY')
      .transform((value) => [value])
      .default(['ANY']),
    onlyReported: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .default(true),
    page: z.coerce.number().min(0).catch(0).optional(),
    pageSize: z.coerce.number().min(1).catch(25).default(25).optional(),
    search: z.string().optional(),
    sort: z.string().default('total-unresolved-reports-desc'),
    studioId: z
      .string()
      .transform((value) => [value])
      .optional(),
    studioName: z
      .string()
      .transform((value) => [value])
      .optional(),
    studioNameSearch: z.string().optional(),
    tagId: z
      .string()
      .transform((value) => [value])
      .optional(),
    userEmail: z
      .string()
      .transform((value) => [value])
      .optional(),
    userId: z
      .string()
      .transform((value) => [value])
      .optional(),
    username: z
      .string()
      .transform((value) => [value])
      .optional(),
  }),
  '/games/wrappers': z.object({
    bucketIdentifier: z
      .enum(['BONUS', 'GX', 'STORE'])
      .transform((value) => [value])
      .catch([])
      .optional(),
    default: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .optional(),
    gameEngineId: z
      .string()
      .transform((value) => [value])
      .catch([])
      .optional(),
    page: z.coerce.number().min(0).catch(0).optional(),
    pageSize: z.coerce.number().min(1).catch(50).default(50),
  }),
  '/mods/all': z.object({
    blocked: z.enum(['true', 'false']).catch('false').optional(),
    crxIdSearch: z.string().optional(),
    expandTags: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .default(false),
    feedAlias: z.string().optional(),
    feedId: z.string().optional(),
    hasUnresolvedReports: z
      .enum([
        'ANY',
        'MISCATEGORIZED',
        'OFFENSIVE_MATERIAL',
        'SPAM',
        'UNAUTHORIZED_DISTRIBUTION',
        'NONE',
      ])
      .transform((value) => [value])
      .optional(),
    internalTrack: z.enum(['published', 'not-published']).optional(),
    loadActiveState: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .optional(),
    modIdSearch: z.string().optional(),
    modKit: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .default(false),
    modShortIdSearch: z.string().optional(),
    onlyDiagnosticsFailed: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .default(false),
    onlyReported: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .optional(),
    page: z.coerce.number().min(0).catch(0).optional(),
    pageSize: z.coerce.number().min(1).catch(50).default(50),
    publicTrack: z.enum(['published', 'not-published']).default('published'),
    runnerVersionName: z.string().optional(),
    search: z.string().optional(),
    sort: z.string().optional(),
    studioId: z.string().optional(),
    studioName: z.string().optional(),
    studioNameSearch: z.string().optional(),
    tagAlias: z.string().optional(),
    tagId: z.string().optional(),
  }),
  '/mods/gms-runners': z.object({
    deprecationStatus: z.enum(['ALL', 'DEPRECATED', 'NON_DEPRECATED']).default('ALL'),
    page: z.coerce.number().min(0).catch(0).optional(),
    pageSize: z.coerce.number().min(1).catch(25).default(25).optional(),
  }),
  '/mods/mod': z.object({ modId: z.string() }),
  '/mods/mod-transfer': z.object({ transferId: z.string().default('') }),
  '/mods/mod-transfers': z.object({
    modId: z.string().optional(),
    originalStudioId: z.string().optional(),
    page: z.coerce.number().min(0).catch(0).optional(),
    pageSize: z.coerce.number().min(1).catch(25).default(25).optional(),
    status: z.enum(['ABORTED', 'APPROVED', 'EXPIRED', 'PENDING', 'REJECTED']).optional(),
    targetStudioId: z.string().optional(),
  }),
  '/mods/odk': z.object({ keyId: z.string() }),
  '/mods/reported-mod': z.object({ modId: z.string() }),
  '/mods/reported-mods': z.object({
    blocked: z.enum(['true', 'false']).catch('false').optional(),
    crxIdSearch: z.string().optional(),
    feedAlias: z.string().optional(),
    feedId: z.string().optional(),
    hasUnresolvedReports: z
      .enum([
        'ANY',
        'MISCATEGORIZED',
        'OFFENSIVE_MATERIAL',
        'SPAM',
        'UNAUTHORIZED_DISTRIBUTION',
        'NONE',
      ])
      .transform((value) => [value])
      .catch([])
      .default('ANY'),
    internalTrack: z.enum(['published', 'not-published']).optional(),
    loadActiveState: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .optional(),
    modIdSearch: z.string().optional(),
    modKit: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .default(false),
    modShortIdSearch: z.string().optional(),
    onlyDiagnosticsFailed: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .default(false),
    onlyReported: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .optional(),
    page: z.coerce.number().min(0).catch(0).optional(),
    pageSize: z.coerce.number().min(1).catch(50).default(50),
    publicTrack: z.enum(['published', 'not-published']).default('published'),
    runnerVersionName: z.string().optional(),
    search: z.string().optional(),
    sort: z.string().optional(),
    studioId: z.string().optional(),
    studioName: z.string().optional(),
    studioNameSearch: z.string().optional(),
    tagAlias: z.string().optional(),
    tagId: z.string().optional(),
  }),
  '/offers/all': z.object({
    activeState: z.enum(['ANY', 'ACTIVE', 'NOT_ACTIVE']).default('ANY'),
    archivedState: z.enum(['ANY', 'ARCHIVED', 'NOT_ARCHIVED']).default('ANY'),
    page: z.coerce.number().min(0).catch(0).optional(),
    pageSize: z.coerce.number().min(1).catch(25).default(25).optional(),
    partnerId: z.string().optional(),
  }),
  '/offers/offer': z.object({ offerId: z.string() }),
  '/offers/offer-code': z.object({ codeId: z.string(), offerId: z.string() }),
  '/offers/offer-settings': z.object({ offerId: z.string() }),
  '/quests/all': z.object({
    activeState: z.enum(['ANY', 'ACTIVE', 'NOT_ACTIVE']).default('ANY'),
    aliasSearch: z.string().optional(),
    collectionId: z
      .string()
      .transform((value) => [value])
      .optional(),
    page: z.coerce.number().min(0).catch(0).optional(),
    pageSize: z.coerce.number().min(1).catch(25).default(25).optional(),
    partOfAnyCollection: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .optional(),
    sort: z
      .enum(['collection-order-asc', 'collection-order-desc', 'created-at-asc', 'created-at-desc'])
      .transform((value) => [value])
      .optional(),
  }),
  '/quests/collections/all': z.object({
    page: z.coerce.number().min(0).catch(0).optional(),
    pageSize: z.coerce.number().min(1).catch(25).default(25).optional(),
  }),
  '/quests/collections/collection': z.object({
    activeState: z.enum(['ANY', 'ACTIVE', 'NOT_ACTIVE']).default('ANY').optional(),
    alias: z.string().optional(),
    aliasSearch: z.string().optional(),
    collectionId: z
      .union([z.string().transform((value) => [value]), z.array(z.string())])
      .optional(),
    page: z.coerce.number().min(0).catch(0).optional(),
  }),
  '/quests/quest': z.object({ questId: z.string() }),
  '/settings/asset-variants-config': z.object({ configId: z.string() }),
  '/settings/asset-variants-profile': z.object({ profileKey: z.string() }),
  '/settings/general': z.never().optional(),
  '/stickers/all': z.object({
    active: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .optional(),
    page: z.coerce.number().min(0).catch(0).optional(),
    pageSize: z.coerce.number().min(1).catch(25).default(25).optional(),
    titleSearch: z.string().optional(),
  }),
  '/stickers/respects': z.object({
    contributorId: z.string().optional(),
    gameId: z.string().optional(),
    page: z.coerce.number().min(0).catch(0).optional(),
    pageSize: z.coerce.number().min(1).catch(25).default(25).optional(),
    sort: z
      .enum(['creation-date-asc', 'creation-date-desc'])
      .transform((value) => [value])
      .optional(),
    stickerActive: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .optional(),
    userId: z.string().optional(),
  }),
  '/stickers/sticker': z.object({ stickerId: z.string() }),
  '/studios/all': z.object({
    canUploadCustomGames: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .optional(),
    canUploadCustomGamesOnDevPortal: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .optional(),
    hasMonetization: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .optional(),
    nameSearch: z.string().optional(),
    onlyWithDiscoverableGames: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .optional(),
    onlyWithGames: z
      .enum(['true', 'false'])
      .catch('false')
      .transform((value) => value === 'true')
      .default(true),
    page: z.coerce.number().min(0).catch(0).optional(),
    pageSize: z.coerce.number().min(1).catch(25).default(25).optional(),
    sort: z.enum(['name-asc', 'name-desc']).optional(),
    studioIds: z
      .string()
      .transform((value) => [value])
      .optional(),
    userId: z.string().optional(),
    userIds: z.union([z.string().transform((value) => [value]), z.array(z.string())]).optional(),
  }),
  '/studios/season': z.object({ seasonId: z.string() }),
  '/studios/studio': z.object({ studioId: z.string() }),
  '/users/bank-account': z.object({
    accountId: z.string(),
    userId: z.string(),
  }),
  '/users/user': z.object({ userId: z.string() }),
} as const
